<template>
  <v-card min-height="100vh" flat color="transparent" class="xw-full">
    <v-card-title class="xflex xflex-row xjustify-between xitems-center xpx-0">
      <Filters
        :value="selectedRange"
        @change="navigate"
        @refetch="fetch(selectedRange)"
        type="custom"
      >
      </Filters>
    </v-card-title>
    <PTOTable
      :loading="loading"
      :loading-more="loading_more"
      :items="items"
      @refetch="fetch(selectedRange)"
      :empty-text="
        selectedType === 'all'
          ? 'No PTO found for the selected date range.'
          : 'No pending PTO found for the selected date range.'
      "
    ></PTOTable>
    <v-card-actions
      v-if="next_page_url"
      :class="[mdUp ? 'xabsolute xbottom-0 xh-[60px]' : '']"
      class="xw-full xflex xflex-col xjustify-center xitems-center"
    >
      <btn-tooltip
        tip="Load more"
        color="primary"
        outlined
        @click="fetchMore"
        :loading="loading_more"
        :disabled="loading_more"
      >
        Load more
      </btn-tooltip>
    </v-card-actions>
  </v-card>
</template>

<script>
import moment from "moment";
import Filters from "../components/Filters.vue";
import PTOTable from "../components/PTOTable.vue";

export default {
  data() {
    return {
      loading: false,
      loading_more: false,
      items: [],
      selectedRange: [
        moment().subtract(5, "day").format("YYYY-MM-DD"), // start
        moment().format("YYYY-MM-DD")
      ],
      next_page_url: null,
    };
  },
  computed: {
    selectedType() {
      return this.$route.name.includes("app-pto-all") ? "all" : "pending";
    },
  },
  mounted() {
    this.$event.$on("refetch-ptos", () => {
      if (Array.isArray(this.selectedRange) && this.selectedRange.length === 2) {
        const [start, end] = this.selectedRange; // Destructure values
        
        this.fetch([start, end]);
      } else {
        console.error('selectedRange is invalid:', this.selectedRange);
      }
    });
  },
  watch: {
    "$route.params": {
      handler(params) {
        const { start, end } = params || {};

        this.selectedRange = [
          start || moment().subtract(5, "day").format("YYYY-MM-DD"),
          end || moment().format("YYYY-MM-DD")
        ];

        if (this.selectedRange[0] && this.selectedRange[1]) {
          this.fetch(this.selectedRange);
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    navigate(val) {
      this.$router
        .push({
          name: `app-pto-${this.selectedType}-custom`,
          params: { start: val[0], end: val[1] },
        })
        .catch(() => {});
    },
    fetch(range) {
      const [start, end] = range || [];

      if (!start || !end) {
        console.error('Invalid date range provided:', range);
        return;
      }

      this.loading = true;
      this.$axios
        .get(`api/ptos/range/${start}/${end}?type=${this.selectedType}`)
        .then(({ data }) => {
          this.items = data.data;
          this.next_page_url = data.next_page_url;
        })
        .finally(() => (this.loading = false));
    },
    fetchMore() {
      this.loading_more = true;
      this.$axios
        .get(this.next_page_url)
        .then(({ data }) => {
          this.items.push(...data.data);
          this.next_page_url = data.next_page_url;
        })
        .finally(() => (this.loading_more = false));
    },
  },
  components: { Filters, PTOTable },
};
</script>

<style lang="scss" scoped></style>
